/* Reset some basic elements */
body, h1, h2, p, ul {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* General body styling */
  body {
    font-family: 'Neuemontreal', sans-serif;
    background-color: #121212;
    color: #FFFFFF;
  }
  
  .home {
    position: relative;
    overflow: hidden;
  }
  
  /* Video container styling */
  .video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }
  
  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
  }
  
  .hero-section {
    text-align: left;
    padding: 4rem 16rem;
    background-color: rgba(0, 0, 0, 0.5); /* Add some background color with transparency for readability */
    color: #FFFFFF;
    position: relative;
    height: 90vh; /* Full viewport height */
    z-index: 1;
  }

  .hero-content {
    margin: 16rem 3rem;
  }

  @media (max-width: 1200px) {
    .hero-section {
      padding: 4rem 1rem;
    }
  }

  .hero-content .block {
    display: block; /* Force each word to appear on a new line */
  }
  
  .hero-section h1 {
    font-size: 3.3rem;
    font-weight: 500;
  }
  
  .hero-section p {
    font-size: 1rem;
    margin: 1rem 0;
  }

/* Mission section styling */
.mission-section {
  padding: 4rem 20rem;
  background-color: rgba(0, 0, 0, 0.957); /* Light background for the mission section */
  color: #f0f0f0e5; /* Dark text color for contrast */
  text-align: left;
  margin-bottom: 5rem;
}

@media (max-width: 600px) {
  .mission-section {
    padding: 4rem 1rem;
  }
}

.mission-statement h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #f0f0f0;
}

.mission-content {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: auto;
}

.mission-statement {
  margin-bottom: 1.5rem;
}

.mission-statement h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.mission-statement p {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.mission-link {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: #f0f0f0a9;
  text-decoration: none;
}

.mission-link .arrow {
  margin-left: 0.5rem;
  font-size: 1.5rem;
}
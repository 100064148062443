body, h1, h2, p, ul {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #121212;
  color: #FFFFFF;
}

/* Navbar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #1F1F1F;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-links {
  list-style: none;
  display: flex;
}

.navbar-links li {
  margin-right: 2rem;
}

.navbar-links li a {
  color: #FFFFFF;
  text-decoration: none;
  font-weight: bold;
}

.navbar-links li a:hover {
  color: #1DB954;
}

.navbar-actions .sign-in-btn, .navbar-actions .request-demo-btn {
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
}

.navbar-actions .sign-in-btn {
  background-color: #2A2A2A;
  color: #FFFFFF;
}

.navbar-actions .request-demo-btn {
  background-color: #1DB954;
  color: #FFFFFF;
}

/* Footer styling */
.footer {
  background-color: #1F1F1F;
  color: #FFFFFF;
  text-align: center;
  padding: 1rem;
  position: fixed;
  width: 100%;
  bottom: 0;
}

/* Home page styling */
.hero-section {
  text-align: center;
  padding: 4rem 2rem;
  background-color: #000000;
  color: #FFFFFF;
}

.hero-section h1 {
  font-size: 3rem;
}

.hero-section p {
  font-size: 1.25rem;
  margin: 1rem 0;
}

.beta-signup {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.beta-signup input {
  padding: 0.5rem;
  margin-right: 0.5rem;
  border: none;
  border-radius: 4px;
}

.beta-signup button {
  padding: 0.5rem 1rem;
  background-color: #1DB954;
  border: none;
  border-radius: 4px;
  color: #FFFFFF;
  cursor: pointer;
}

.products-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 2rem 1rem;
}

/* Product card styling */
.product-card {
  background-color: #1F1F1F;
  border: 1px solid #2A2A2A;
  border-radius: 5px;
  padding: 1rem;
  width: 250px;
  margin: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-card h3 {
  color: #1DB954;
  margin-bottom: 0.5rem;
}

.product-card p {
  color: #B3B3B3;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 2rem;
  height: 1vh; /* Setting a specific height */
  background-color: transparent;
}

/* If navbar elements are not visible against some backgrounds, consider adding text shadows or adjusting colors */
.navbar-links li a {
  color: #FFFFFF; /* White text */
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 2px black; /* Adding a subtle shadow for better visibility */
}

.navbar-links li a:hover {
  color: #E0FFFF; /* Green on hover */
}

.navbar-actions {
  display: flex;
  align-items: center;
}

.navbar-actions .schedule-meeting-btn {
    padding: 0.5rem 1.5rem;
    background-color: #444444;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    color: #f0f0f0a9;
    font-size: .80rem;
  }
  
  .navbar-actions .schedule-meeting-btn:hover {
    background-color: #e0ffff84;
  }

.navbar-logo {
  font-size: 1.65rem;
  font-weight: 135;
  color: #FFFFFF;
  display: flex;
  align-items: center; /* Ensures the logo and text are aligned */
}

.navbar-logo-image {
  width: 50px; /* Set the width of the logo */
  height: auto; /* Maintain the aspect ratio */
}


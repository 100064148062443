
.contact-us {
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: transparent; /* Transparent background */
    padding: 0.5rem;
    border-radius: 50px;
    gap: 1rem; /* Space between elements */
    margin-top: 1rem; /* Additional space from subtitle */
}

.contact-input {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 50px;
    outline: none;
    width: 250px;
    background-color: #222222;
    color: #FFFFFF;
    font-size: .80rem;
}

.contact-button {
    margin: 0 1rem;
    padding: 0.5rem 1.5rem;
    background-color: #444444;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    color: #f0f0f0a9;
    font-size: .80rem;
}

@media (max-width: 1200px) {
    .contact-button {
        margin: 1rem 5rem;
    }
  }

.email-sent-message {
    margin: 0 1rem;
    padding: 0.5rem 1.5rem;
    color: #f0f0f0a9;
    font-size: .80rem;
  }